<script lang="ts" setup></script>

<template>
  <div class="auth-page">
    <div class="header-wrapper">
      <LayoutAuthHeader />
    </div>

    <div class="auth-page__content container">
      <div class="auth-page__content-left">
        <div class="logo">
          <img
            src="~/assets/img/logo.png"
            alt="AutoGeorgia"
          >
        </div>
      </div>
      <div class="auth-page__content-right">
        <div class="form-wrapper">
          <UiFormBase>
            <slot />
          </UiFormBase>
        </div>
      </div>
    </div>

    <!-- Layout: auth -->
  </div>
</template>

<style lang="scss" scoped>
@media print {
  .auth-page {
    display: none!important;
  }
}
.auth-page {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  background-image: url(/assets/img/pages/auth/bg.png);
  background-position: top right;
  background-size: auto;
  background-repeat: no-repeat;

  .header-wrapper {
    position: absolute;
    top: 40px;
    left: 0;
    width: 100%;

    @media only screen and (max-width: $laptopS) {
      top: 20px;
    }
  }

  &__content {
    display: flex;
    flex: 1;
    align-items: center;

    @media only screen and (max-width: $laptopS) {
      flex-direction: column;
      padding: 70px 16px 50px;
    }

    &-left {
      width: 50%;
      padding-left: 16px;

      @media only screen and (max-width: $laptopS) {
        width: 100%;
        max-width: 300px;
        padding: 0;
        margin-bottom: 30px;
      }
    }

    &-right {
      width: 50%;
      padding: 0 152px 0 29px;

      @media only screen and (max-width: $laptopS) {
        width: 100%;
        max-width: 450px;
        padding: 0;
      }
    }
  }
}
</style>
