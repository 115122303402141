<template>
  <div class="form-wrapper">
    <slot />
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
.form-wrapper {
  position: relative;
  z-index: 1;
  background: #ffffff;
  border-radius: 10px;
  padding: 30px 50px;

  @media only screen and (max-width: $laptopS) {
    padding: 15px 10px;
  }

  &::before,
  &::after {
    position: absolute;
    z-index: -2;
    content: "";
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border-radius: 10px;
    background: linear-gradient(45deg, rgba(45, 45, 134, 1) 0%, rgba(240, 73, 79, 1) 100%);
  }

  &::after {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    z-index: -1;
  }
}
</style>
